import '../styles/common.css';

import { BgWrapper } from '../components/shared/components';
import CollapsibleSection from '../components/shared/collapsable';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';
import peacemaker from '../images/index/peacemaker-badge.png';

export default ({ data }) => {
	const title = data.wordpressPage.acf.title;

	let features = [];
	data.wordpressPage.acf.features.split('\n').map((rString) => {
		let parts = rString.split('###');

		if (parts[0]) {
			features.push({
				image: peacemaker,
				title: parts[0],
				text: parts[1]
			});
		}
	});

	return (
		<>
			<BgWrapper bg={data.bg.childImageSharp.fluid.src} position={'top'}>
				<Layout>
					<SEO
						title={meta.netsurfplay.title}
						description={meta.netsurfplay.metaDescription}
						image={data.heroImg.childImageSharp.fluid.src}
					/>

					<div style={{ paddingTop: '8.5rem' }}>
						<div className="d-none d-lg-block play-container">
							<Img
								imgStyle={{ height: 400, objectFit: 'fill' }}
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div
							className="d-lg-none play-container"
							style={{ paddingBottom: '5rem' }}
						>
							<Img
								objectFit="cover"
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div style={{ maxWidth: 1280, margin: 'auto' }}>
							<SectionTitle>
								<TitleH1>{title}</TitleH1>
								<hr className="center" />
							</SectionTitle>
						</div>

						<BgWrapper
							bg={data.videoBg.childImageSharp.fluid.src}
							color={'transparent'}
						>
							<div className="position-relative row d-flex justify-content-center w-100">
								<div className="video-wrap">
									<iframe
										width="700"
										height="360"
										src="https://www.youtube.com/embed/qJicuU5bc1g"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
										title="NetSurf"
										style={{ maxWidth: '100%' }}
									></iframe>
								</div>
							</div>
						</BgWrapper>
						<div className="my-5 row d-flex justify-content-center">
							<div className="col-12 col-lg-11 col-xl-9 row d-flex justify-content-center">
								{features.map(({ image, title, text }, index) => (
									<div
										className="my-3 text-center col-12 col-sm-6 col-lg-4 px-md-5 my-sm-5"
										key={index}
									>
										<ScrollAnimation
											animateIn="fadeIn"
											animateOnce={true}
										>
											<div
												className="d-flex justify-content-center align-items-center"
												style={{ minHeight: '100px' }}
											>
												<img
													src={image}
													className="img-fluid"
													alt={title}
													width={60}
													height={60}
												/>
											</div>
											<div className="px-sm-4">
												<h3>{title}</h3>
												<p>{text}</p>
											</div>
										</ScrollAnimation>
									</div>
								))}
							</div>
						</div>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<CollapsibleSection
								faqs={data.allWordpressWpFaqs.edges.map(
									({ node }) => node
								)}
								sectionTitle={
									<>
										<h2
											className="container mb-5 d-flex justify-content-center align-items-center"
											style={{ whiteSpace: 'pre-wrap' }}
										>
											Често задавани въпроси
										</h2>
										<hr className="center blue" />
									</>
								}
							/>
						</ScrollAnimation>
					</div>
				</Layout>
			</BgWrapper>
		</>
	);
};

export const query = graphql`
	query zashtita {
		wordpressPage(slug: { eq: "internet-zashtita" }) {
			acf {
				features
				title
			}
		}
		allWordpressWpFaqs(filter: { acf: { category: { eq: "peacemaker" } } }) {
			edges {
				node {
					title
					content
				}
			}
		}
		bg: file(relativePath: { eq: "netsurfplay/bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
		heroImg: file(relativePath: { eq: "peacemaker/peacemaker.png" }) {
			childImageSharp {
				fluid(maxHeight: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBanner: file(relativePath: { eq: "netsurfplay/video-banner.webp" }) {
			childImageSharp {
				fluid(maxHeight: 700) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBg: file(relativePath: { eq: "netsurfplay/video-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

